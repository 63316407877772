* {
  margin: 0;
  box-sizing: border-box;
}
:root {
  --darkblue: #006592;
  /* --darkblue: #2596be; */
  --headerColor:#00AEC0;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
  .fade-image {
    opacity: 1;
    transition: opacity 0.8s ease-in-out;
  }

  .fade-image.fade {
    opacity: 0.4;
  }
html.modal-enabled {
  overflow: hidden !important;
}
body {
  overflow-x: hidden;
  font-family: "ubuntu-regular";
}
body {
}
a {
  text-decoration: none;
}
:root{
  --blue:var(--headerColor)
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  display: none;
}
.wrapper {
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 1440px) {
  .wrapper {
    width: 90%;
  }
}
/* fontfamily */

@font-face {
  font-family: "ubuntu-regular";
  src: url(../fonts/Ubuntu-Regular.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-medium";
  src: url(../fonts/Ubuntu-Medium.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-medium-italic";
  src: url(../fonts/Ubuntu-MediumItalic.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-italic";
  src: url(../fonts/Ubuntu-Italic.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-bold";
  src: url(../fonts/Ubuntu-Bold.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-bold-italic";
  src: url(../fonts/Ubuntu-BoldItalic.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-light";
  src: url(../fonts/Ubuntu-Light.woff) format(woff);
}
@font-face {
  font-family: "ubuntu-light-italic";
  src: url(../fonts/Ubuntu-LightItalic.woff) format(woff);
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}
#background-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  /* position: fixed; */
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.shake:hover .rise-shake {
  animation: jump-shaking 0.83s infinite;
}

@keyframes jump-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(-9px);
  }
  35% {
    transform: translateY(-9px) rotate(17deg);
  }
  55% {
    transform: translateY(-9px) rotate(-17deg);
  }
  65% {
    transform: translateY(-9px) rotate(17deg);
  }
  75% {
    transform: translateY(-9px) rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

/* course card animation */
@keyframes hoverAnimation {
  from {
    background: linear-gradient(
      0deg,
      rgba(0, 100, 147, 1) 0%,
      rgba(0, 177, 195, 1) 100%
    );
  }
  to {
    background: linear-gradient(
      -45deg,
      rgba(0, 100, 147, 1) 0%,
      rgba(0, 177, 195, 1) 100%
    );
  }
}

/* Apply the animation to the div when hovering */
.course:hover {
  animation: hoverAnimation 0.3s ease;
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: url(../images/header/headeroverlay.png);
  z-index: 10;
  transition: top 0.3s;
}

.sticky-header.hidden {
  top: -200px; /* Adjust this value based on your header height */
}

/* country slider */
.con .slider {
  max-width: 1110px;
  margin: 0 auto;
}

.con .slick-track {
  padding-top: 53px;
  padding-bottom: 53px;
}

.con .slick-slide {
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.con .slick-slide:not(.slick-current) {
  filter: brightness(0.5);
}

.con .slick-slide.slick-current {
  transform: scale(1.35);
  position: relative;
  z-index: 1;
}

.con .slick-slide img {
  width: 100%;
}
/* body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  display: none;
}

body::-webkit-scrollbar-thumb {
  display: none;

  background: linear-gradient(
    9deg,
    rgba(0, 152, 246, 1) 0%,
    #005cb9 100%
  ) !important;
  outline: 1px solid #009cb9 !important;
  border-radius: 50px;
}
body::-webkit-scrollbar {
  display: none;

  width: 0px !important;
} */
.nav-item {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  transition: 0.4s ease;
}

.nav-item.show {
  opacity: 1;
}
#anime {
  display: block;
  width: 100%;
}
#mobile-video {
  display: none;
}
@media all and (max-width: 480px) {
  #mobile-video {
    width: 100%;
    display: block;
    height: 100vh;
    object-fit: cover;
    /* position: fixed; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  #background-video {
    display: none;
  }
}
.basic-single {
  border-color: #006592 !important;
  color: #006592;
  cursor: pointer;
  width: 200px;
}
.basic-single-modal {
  border-color: #006592 !important;
  color: #006592;
  cursor: pointer;
  width: 100%;
}
.enquiry {
  /* background-color: #016493;
  border: 1px solid transparent; */
  color: #828282;
  font-size: 14px;
}

.botpenguin-right{
  bottom: 116px;
  right: 20px;
}