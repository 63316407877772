/* 
 * jquery.hislide.min.css
 * Date: 2017-02-15
 * Version: v1.0.0
 * Author: Cphayim
 * Site: https://cphayim.me 
*/

.hi-slide {
  position: relative;
  width: 754px;
  height: 292px;
  margin: 115px auto 0;
}
.hi-slide .hi-next,
.hi-slide .hi-prev {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 80px;
  margin-top: -40px;
  border-radius: 5px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
  background-color: #666;
  color: #fff;
  opacity: 0.7;
  transition: all 0.6s;
  font-size: 30px;
}
.hi-slide .hi-next:hover,
.hi-slide .hi-prev:hover {
  opacity: 1;
  background-color: #444;
}
.hi-slide .hi-prev {
  left: -260px;
}
.hi-slide .hi-prev::before {
  content: "<";
}
.hi-slide .hi-next {
  right: -260px;
}
.hi-slide .hi-next::before {
  content: ">";
}
.hi-slide > ul {
  list-style: none;
  position: relative;
  width: 920px;
  height: 170px;
  margin: 0;
  padding: 0;
  position: relative;
}
.hi-slide > ul::after {
  content: "";
  width: 100%;
  bottom: 0;
  left: -107px;
  height: 2px;
  background-color: #00b1c3;
  position: absolute;
}

.hi-slide > ul > li {
  overflow: hidden;
  position: absolute;
  z-index: 0;
  left: 377px;
  top: 146px;
  width: 0;
  border-radius: 20px;
  height: 0;
  margin: 0;
  padding: 0;
  border: 2px solid #fff;
  /* border-radius: 5px; */
  background-color: #333;
  cursor: pointer;
}

.hi-slide > ul > li > img {
  width: 100%;
  height: 100%;
}
div.round {
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: -91px;
  width: 870px;
  position: absolute;
  bottom: 113px;
}
div.round span {
  width: 20px;
  height: 20px;
  background: rgb(0, 152, 246);
  background: linear-gradient(
    9deg,
    rgba(0, 152, 246, 1) 0%,
    rgba(0, 92, 185, 1) 100%
  );
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  border-radius: 50%;
}
div.round span.span1 {
  width: 10px;
  height: 10px;
}
div.round span.span2 {
  width: 13px;
  height: 13px;
}
div.round span.span3 {
  width: 16px;
  height: 16px;
}
div.country-name {
  text-align: center;
  margin-top: 30px;
  margin-right: 53px;
}
